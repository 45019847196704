import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { format } from 'date-fns';

import { Poll } from '@models/polls';
import { Question, QuestionData } from '@models/questions';
import { PollsService } from '@api/polls.service';

import { PollModal } from '@modals/poll-modal/poll-modal.component';


@Component({
  selector: 'app-manage-polls',
  templateUrl: './manage-polls.component.html',
  styleUrls: ['./manage-polls.component.scss']
})
export class ManagePollsPage implements OnInit, OnDestroy {

  private bsModalRef: BsModalRef;
  private subscriptions: Subscription[] = [];

  isLoading = false;

  polls: Poll[];
  currPollId: string;

  form: FormGroup;
  questions: Question[];
  currQues: Question;

  showError = false;


  constructor(private fb: FormBuilder,
              private router: Router,
              private pollsSvc: PollsService,
              private bsModal: BsModalService) { }

  ngOnInit() {
    this.form = this.fb.group({
      question: ['', Validators.required]
    });

    this.getPolls();
    this.attachModalEventHandlers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  showAddPoll() {
    this.bsModalRef = this.bsModal.show(PollModal);
  }

  showUpdatePoll(id: string) {
    const initialState = { poll: this.polls.find(p => p.id === id) };
    this.bsModalRef = this.bsModal.show(PollModal, { initialState });
  }

  gotoQuestions(id: string) {
    this.router.navigate(['/admin/manage-questions/' + id]);
  }

  gotoHistory(id: string) {
    this.router.navigate(['/admin/past-sessions/' + id]);
  }

  ///

  private getPolls() {
    this.isLoading = true;
    this.pollsSvc
        .getPolls()
        .subscribe(polls => {
          this.polls = polls.sort((a, b) => -a.createdOn.localeCompare(b.createdOn))
                            .map(p => {
                              p.createdOn = format(new Date(p.createdOn), 'do MMM, yyyy hh:mm a');
                              return p;
                            });

          this.isLoading = false;
        });
  }

  private attachModalEventHandlers() {
    this.subscriptions.push(
      this.bsModal.onHidden.subscribe((reason: string) => {
        console.log('Modal closed', reason);
        if (!reason) {
          this.polls = [];
          this.getPolls();
        }
      })
    );
  }

  // ---

  /*
  private doSubmit() {
    //this.submitted = true;

    if (this.form.invalid)
      return;

    if (this.currQues.id) {
      const quesData: QuestionData = {
        id: this.currQues.id,
        question: this.form.get('question').value,
        sequence: this.currQues.sequence,
        type: this.currQues.type
      };

      this.pollsSvc
          .updateQuestion(quesData)
          .subscribe(success => {
            if (success) {
              this.getQuestions(this.currPollId);
              this.currQues = undefined;
            }

            this.showError = !success;
          });
    }
  }
  */

}
