import { Component } from '@angular/core';


@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayout {

  title = 'Live Feedback';

  constructor() { }

}
