import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@api/auth.service';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayout {

  title = 'Live Feedback';

  isLoggedIn: Observable<boolean>;

  constructor(private auth: AuthService, private router: Router) {
    this.isLoggedIn = auth.isLoggedIn;
  }

  logOut() {
    this.auth.logout().subscribe(success => {
      success && this.router.navigate(['/admin/login']);
    });
  }

}
