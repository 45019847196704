import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Question, QuestionData } from '@models/questions';
import { QuestionsService } from '@api/questions.service';


@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss']
})
export class QuestionModal implements OnInit {

  pollId: string;
  sequence: number;
  question: Question;
  form: FormGroup;
  mode: 'Add' | 'Update';

  submitted = false;
  showError = false;


  constructor(private fb: FormBuilder,
              private modalRef: BsModalRef,
              private questions: QuestionsService) { }

  ngOnInit() {
    this.mode = !!this.question ? 'Update' : 'Add';
    this.form = this.fb.group({
      question: [this.question?.question ?? '', Validators.required],
      instructions: [this.question?.instructions ?? ''],
      type: [this.question?.type ?? 'DESCRIPTIVE', Validators.required],
      maxResponses: [this.question?.maxResponses ?? 2, Validators.required],
      isDelayed: [this.question?.isDelayed ?? false]
    });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return (
      (control.invalid && control.touched) ||
      (control.invalid && control.untouched && this.submitted)
    );
  }

  doSubmit() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    const postData: QuestionData = {
      type: this.form.value.type,
      question: (this.form.value.question as string).trim(),
      instructions: (this.form.value.instructions as string).trim(),
      maxResponses: this.form.value.maxResponses,
      isDelayed: this.form.value.isDelayed,
      sequence: this.question?.sequence ?? this.sequence,
      pollId: this.question?.pollId ?? this.pollId
    };

    this.mode === 'Add'
      ? this.questions.addQuestion(postData).subscribe(this.handleResponse.bind(this))
      : this.questions.updateQuestion(this.question.id, postData).subscribe(this.handleResponse.bind(this));
  }

  handleResponse(success: boolean) {
    success && this.close();
    this.showError = !success;
  }

  close() {
    this.modalRef.hide();
  }

}
