import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import * as Highcharts from 'highcharts';
import WordCloud from 'highcharts/modules/wordcloud';

import { PollSummary, PollHistory, HistorySession, SummarySession, SessionData } from '@models/sessions';
import { PollData, PollResult } from '@models/polls';
import { PollsService } from '@api/polls.service';


@Component({
  selector: 'app-past-sessions',
  templateUrl: './past-sessions.component.html',
  styleUrls: ['./past-sessions.component.scss']
})
export class PastSessionsPage implements OnInit {

  pollId: string;
  summary: PollSummary;
  history: PollHistory;
  currSummary: SummarySession;
  currSession: HistorySession;
  barChartOptions: Highcharts.Options;
  wordCloudOptions: Highcharts.Options;

  loading = false;
  updating = false;
  recordingMode = false;
  currIdx = 0;
  Highcharts = Highcharts;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private polls: PollsService) {
    route.paramMap.subscribe(map => {
      this.pollId = map.get('pollId');

      if (!this.pollId)
        router.navigate(['/admin/manage-polls']);
    });

    WordCloud(Highcharts);
  }

  ngOnInit(): void {
    this.loading = true;
    this.getPollsSummary();
  }

  gotoManagePolls() {
    this.router.navigate(['/admin/manage-polls']);
  }

  formatDate(date: string) {
    return format(new Date(date), 'do MMM, yyyy hh:mm:ss a');
  }

  updateSession(newId: string) {
    this.currIdx = 0;
    this.currSummary = this.summary.reports.find(s => s.sessionId === newId);
    this.currSession = this.history.reports.find(s => s.sessionId === newId);
    this.updatePoll(this.currIdx);
  }

  updatePoll(newIndex: number) {
    if (newIndex >= this.currSession.results.length || newIndex < 0)
      return;

    this.currIdx = newIndex;
    this.updating = true;

    const question = this.currSession.results[newIndex];

    question.type === 'DESCRIPTIVE' || question.type === 'AMOUNT'
      ? this.setWordCloudChartOptions(question)
      : this.setBarChartOptions(question);
  }

  ///

  private getPollsSummary() {
    this.polls
      .getPollsSummary(this.pollId)
      .subscribe(summary => {
        summary.reports.sort((a, b) => -a.startTime.localeCompare(b.startTime));

        this.summary = summary;
        this.getPollsHistory();
      });
  }

  private getPollsHistory() {
    this.polls
      .getPollsHistory(this.pollId)
      .subscribe(history => {
        history.reports.sort((a, b) => -a.startTime.localeCompare(b.startTime));

        this.history = history;
        this.updateSession(this.history.reports[0].sessionId);
        this.loading = false;
      });
  }

  private setWordCloudChartOptions(data: PollData) {
    this.wordCloudOptions = {
      title: {
        text: data.question,
        style: {
          color: '#333333',
          fontSize: '24px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: `Total ${data.votes} Votes`
      },
      series: [{
        name: 'Votes',
        type: 'wordcloud',
        data: data.options.map(o => ({ name: o.text, weight: o.total }))
      }],
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };

    this.updating = false;
  }

  private setBarChartOptions(data: PollData) {
    this.barChartOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: data.question,
        style: {
          color: '#333333',
          fontSize: '24px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: `Total ${data.votes} Votes`
      },
      legend: {
        enabled: false
      },
      tooltip: {
        //valueSuffix: '%',
        followPointer: true,
        followTouchMove: true,
        pointFormat: 'Votes: <b>{point.votes}</b>',
        enabled: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y}%'
          }
        }
      },
      xAxis: {
        categories: data.options
                        .sort(this.sortOptions)
                        .map(opt => `Text ${opt.optCode} - ${opt.text}`),
        labels: {
          style: {
            fontSize: '16px',
            fontWeight: 'bold'
          }
        },
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        labels: {
          overflow: 'justify'
        },
        title: {
          text: null
        }
      },
      series: [{
        type: 'bar',
        data: data.options
                  .sort(this.sortOptions)
                  .map(o => ({ y: o.percentage, votes: o.total }))
      }]
      /*[{
        name: 'Votes',
        type: 'bar',
        data: data.options
                  .sort((a, b) => a.optCode.localeCompare(b.optCode))
                  .map(o => o.percentage)
      }]*/,
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };

    this.updating = false;
  }

  private sortOptions(opt1: PollResult, opt2: PollResult) {
    return isNaN(+opt1.optCode) || isNaN(+opt2.optCode)
             ? opt1.optCode.localeCompare(opt2.optCode)
             : parseInt(opt1.optCode, 10) - parseInt(opt2.optCode, 10);
  }

}
