import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Question } from '@models/questions';
import { QuestionsService } from '@api/questions.service';

import { QuestionModal } from '@modals/question-modal/question-modal.component';
import { OptionModal } from '@modals/option-modal/option-modal.component';


@Component({
  selector: 'app-manage-questions',
  templateUrl: './manage-questions.component.html',
  styleUrls: ['./manage-questions.component.scss']
})
export class ManageQuestionsPage implements OnInit, OnDestroy {

  private bsModalRef: BsModalRef;
  private subscriptions: Subscription[] = [];

  pollId: string;
  questions: Question[] = [];
  isLoading = false;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private bsModalSvc: BsModalService,
              private questionsSvc: QuestionsService) {
    route.paramMap.subscribe(map => {
      this.pollId = map.get('pollId');

      if (!this.pollId)
        router.navigate(['/admin/manage-polls']);
    });
  }

  ngOnInit(): void {
    this.getQuestions();
    this.attachModalEventHandlers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  gotoManagePolls() {
    this.router.navigate(['/admin/manage-polls']);
  }

  showAddQuestion() {
    const initialState = {
      pollId: this.pollId,
      sequence: this.questions.reduce((p, c) => c.sequence > p ? c.sequence : p, 0) + 1
    };
    this.bsModalRef = this.bsModalSvc.show(QuestionModal, { initialState });
  }

  showUpdateQuestion(id: string) {
    const initialState = { question: this.questions.find(q => q.id === id) };
    this.bsModalRef = this.bsModalSvc.show(QuestionModal, { initialState });
  }

  showAddOption(id: string) {
    const initialState = { questionId: id };
    this.bsModalRef = this.bsModalSvc.show(OptionModal, { initialState });
  }

  showUpdateOption(questionId: string, optionId: string) {
    const initialState = {
      questionId: questionId,
      option: this.questions
                  .find(q => q.id === questionId)
                  .options
                  .find(o => o.id === optionId)
    };
    this.bsModalRef = this.bsModalSvc.show(OptionModal, { initialState });
  }

  ///

  private getQuestions() {
    this.isLoading = true;
    this.questionsSvc
        .getQuestions(this.pollId)
        .subscribe(questions => {
          this.questions = questions;

          this.isLoading = false;
        });
  }

  private attachModalEventHandlers() {
    this.subscriptions.push(
      this.bsModalSvc.onHidden.subscribe((reason: string) => {
        console.log('Modal closed', reason);
        if (!reason) {
          this.questions = [];
          this.getQuestions();
        }
      })
    );
  }

}
