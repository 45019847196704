<nav class="navbar navbar-light navbar-expand-lg">
  <div class="container">
    <a class="navbar-brand">
      <img src="assets/img/logo.png" alt="ENTRADA Logo">
      <span class="d-none d-lg-inline-block">{{title}}</span>
    </a>
  </div>
</nav>

<section class="container">
  <router-outlet></router-outlet>
</section>
