<h3 class="d-flex justify-content-between">
  <span>Manage Questions</span>
  <button class="btn btn-sm btn-outline-secondary" (click)="gotoManagePolls()">Back to Manage Polls</button>
</h3>
<div class="table-responsive-lg">
  <table class="table">
    <tr>
      <th width="68%">Question</th>
      <th>Type</th>
      <th width="18%"><button class="btn btn-outline-secondary btn-sm" (click)="showAddQuestion()">Add New</button></th>
    </tr>
    <tr *ngIf="isLoading">
      <td colspan="3" class="text-muted">Loading...</td>
    </tr>
    <tr *ngIf="!isLoading && questions?.length < 1">
      <td colspan="3" class="text-muted">Please add a question</td>
    </tr>
    <ng-container *ngFor="let q of questions">
      <tr>
        <td>{{ q.question }}</td>
        <td>{{ q.type === 'AMOUNT' ? 'Amount' : q.type === 'DESCRIPTIVE' ? 'Open Text' : q.maxResponses > 1 ? 'Multiple Choice' : 'Single Choice' }}</td>
        <td>
          <div class="btn-group btn-group-sm">
            <button class="btn btn-outline-secondary" (click)="showUpdateQuestion(q.id)">Edit</button>
            <button class="btn btn-outline-secondary" (click)="showAddOption(q.id)"
              *ngIf="q.type !== 'DESCRIPTIVE'">
              Add Option
            </button>
          </div>
        </td>
      </tr>
      <tr *ngIf="q.options.length > 0">
        <td colspan="3" class="small">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tr>
                <th width="10%">SMS Code</th>
                <th width="59%">Option Text</th>
                <th>&nbsp;</th>
              </tr>
              <tr *ngFor="let opt of q.options">
                <td>{{ opt.option }}</td>
                <td>{{ opt.description }}</td>
                <td>
                  <div class="btn-group btn-group-sm">
                    <button class="btn btn-outline-secondary" (click)="showUpdateOption(q.id, opt.id)">Edit</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
