import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest,
  HttpHandler, HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StateService } from '@api/state.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private state: StateService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modReq = req;
    if (this.state.hasToken()) {
      const newHeaders = req.headers.append('Authorization', 'Bearer ' + this.state.token);
      modReq = req.clone({ headers: newHeaders });
    }

    return next.handle(modReq)
               .pipe(tap(
                 () => {},
                 async (err: any) => {
                   if (err instanceof HttpErrorResponse) {
                     if (err.status !== 401)
                       return;

                     const redirectPath = (this.router.url.indexOf('admin') >= 0 ? '/admin': '') + '/login';

                     this.state.hasSession() ? this.state.clearToken() : this.state.clearAll();
                     this.router.navigate([redirectPath]);
                   }
                 }
               ));
  }

}
