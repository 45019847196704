import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { shareReplay, map, catchError, distinctUntilChanged } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedIn$: BehaviorSubject<boolean>;


  constructor(private http: HttpClient, private state: StateService) {
    this.isLoggedIn$ = new BehaviorSubject<boolean>(state.hasToken());
  }

  get isLoggedIn() {
    return this.isLoggedIn$
               .asObservable()
               .pipe(shareReplay(1));
  }

  login(username: string, password: string) {
    return this.http
               .post<any>(environment.apiUrl + '/login', { username, password })
               .pipe(
                 distinctUntilChanged(),
                 map((data: { token: string }) => {
                   console.log('Logged in!');

                   this.state.token = data.token;
                   this.isLoggedIn$.next(true);

                   return true;
                 }),
                 catchError(this.handleError())
               );
  }

  logout() {
    return this.http
               .post(environment.apiUrl + '/logout', {})
               .pipe(
                 distinctUntilChanged(),
                 map(_body => {
                   console.log('Logged out!');

                   this.state.clearAll();
                   this.isLoggedIn$.next(false);

                   return true;
                 }),
                 catchError(this.handleError())
               );
  }

  ///

  private handleError() {
    return (err: any) => {
      console.error('AUTH ERROR:', err);

      this.state.clearToken();
      this.isLoggedIn$.next(false);

      return of(false);
    }
  }

}
