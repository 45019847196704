import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Question, Option, QuestionData, OptionData } from '@models/questions';
import { handleError } from '@utils/handleError';
import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  private QUESTION_URL = environment.apiUrl + '/question';
  private OPTION_URL = this.QUESTION_URL + 's/option';


  constructor(private http: HttpClient, private state: StateService) { }

  getQuestions(pollId: string) {
    return this.http
               .get<Question[]>(this.QUESTION_URL, this.getHeaders(pollId))
               .pipe(
                 map(questions => questions.sort((a, b) => a.sequence - b.sequence)
                                           .map(q => {
                                             q.voteStatus = 'NONE';
                                             q.options = q.options.sort(this.sortOptions);

                                             return q;
                                           }))
               );
  }

  addQuestion(data: QuestionData) {
    return this.http
               .post(this.QUESTION_URL, data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  updateQuestion(id: string, data: QuestionData) {
    return this.http
               .put(this.QUESTION_URL + '/' + id, data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  addOption(data: OptionData) {
    return this.http
               .post(this.OPTION_URL, data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  updateOption(id: string, data: OptionData) {
    return this.http
               .put(this.OPTION_URL + '/' + id, data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }


  startPollForQuestion(questionID: string) {
    return this.http
               .post(this.QUESTION_URL + '/' + questionID + '/true', null)
               .pipe(
                 map(_ => {
                   this.state.setQuestionStatus(questionID, 'VOTING');

                   return true;
                 }),
                 catchError(handleError())
               );
  }

  endPollForQuestion(questionID: string) {
    return this.http
               .post(this.QUESTION_URL + '/' + questionID + '/false', null)
               .pipe(
                 map(_ => {
                   this.state.setQuestionStatus(questionID, 'DONE');

                   return true;
                 }),
                 catchError(handleError())
               );
  }

  ///

  private getHeaders(pollId: string) {
    return { headers: { 'Poll-ID': pollId } };
  }

  private sortOptions(opt1: Option, opt2: Option) {
    return (isNaN(+opt1.option) || isNaN(+opt2.option))
             ? opt1.option.localeCompare(opt2.option)
             : parseInt(opt1.option, 10) - parseInt(opt2.option, 10);
  }

}
