import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import WordCloud from 'highcharts/modules/wordcloud';

import { Poll, LivePollData } from '@models/polls';
import { Question, QuestionType } from '@models/questions';
import { AuthService } from '@api/auth.service';
import { PollsService } from '@api/polls.service';
import { QuestionsService } from '@api/questions.service';
import { LiveDataService } from '@api/live-data.service';
import { StateService } from '@api/state.service';


@Component({
  selector: 'app-current-session',
  templateUrl: './current-session.component.html',
  styleUrls: ['./current-session.component.scss']
})
export class CurrentSessionPage implements OnInit, OnDestroy {

  private submitted = false;

  form: FormGroup;
  barChartOptions: Highcharts.Options;
  wordCloudOptions: Highcharts.Options;
  chartType: QuestionType;
  polls: Poll[];
  currPoll: Poll;
  questions: Question[];
  liveData: Subscription;

  Highcharts = Highcharts;
  loading = false;
  updating = false;
  showError = false;
  sessionStarted = false;
  currIdx = 0;
  barChartHeight = "500px"
  barChartStyle = {
    fontSize: '12px',
    fontWeight: 'bold',
    wordBreak: 'none',
    textOverflow: 'allow'
  }


  constructor(private fb: FormBuilder,
              private router: Router,
              private auth: AuthService,
              private pollsSvc: PollsService,
              private questionsSvc: QuestionsService,
              private liveDataSvc: LiveDataService,
              private stateSvc: StateService) {
    WordCloud(Highcharts);

    auth.isLoggedIn.subscribe(async state => {
      !state && await this.router.navigate(['/login']);
    });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'pollId': [this.stateSvc.poll ?? '', Validators.required]
    });

    this.getPolls();
  }

  ngOnDestroy(): void {
    if (!this.stateSvc.hasToken() || !this.sessionStarted || this.questions.length < 1)
      return;

    const pollComplete = this.questions
                             .every(q => this.stateSvc.hasQuestion(q.id) &&
                                         this.stateSvc.getQuestionStatus(q.id) === 'DONE');

    if (pollComplete)
      this.pollsSvc
          .endSession(this.currPoll.id)
          .subscribe(_ => {
            console.log("Session stopped!");

            this.stateSvc.clearSession();
            this.stateSvc.clearPoll();
            this.stateSvc.clearQuestions();
          });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return (
      (control.invalid && control.touched) ||
      (control.untouched && this.submitted)
    );
  }

  startSession() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    const pollId = this.form.value.pollId as string;

    this.loading = true;

    //*
    this.pollsSvc
        .startSession(pollId)
        .subscribe(success => {
          if (!success) {
            this.showError = true;
            return;
          }

          this.getQuestions(pollId);
        });
    /*/
    this.getQuestions(pollId);
    //*/
  }

  updatePoll(newIndex: number) {
    if (newIndex >= this.questions.length || newIndex < 0)
      return;

    this.updateBarCharHight(newIndex);
    this.updating = true;
    this.currIdx = newIndex;

    const question = this.questions[newIndex];

    this.chartType = question.type;
    this.chartType === 'DESCRIPTIVE' || this.chartType === 'AMOUNT'
      ? this.setWordCloudChartOptions(question)
      : this.setBarChartOptions(question);

    if (question.voteStatus !== 'NONE')
      this.pollsSvc
          .getCurrentPollData(this.currPoll.id)
          .subscribe(pollData => {

            if (pollData.reports.length < 1)
              return;

            const data = pollData.reports[0]
                                .results
                                .find(r => r.question === question.question);

            if (!!data)
              this.chartType === 'DESCRIPTIVE'
                ? this.updateWordCloudChartData(data)
                : data.type === 'AMOUNT' 
                  ? this.updateAmountChartData(data)
                  : this.updateBarChartData(data);
          });
  }

  startPoll() {
    this.updateBarCharHight(this.currIdx);
    this.questionsSvc
        .startPollForQuestion(this.questions[this.currIdx].id)
        .subscribe(success => {
          if (!success) {
            this.showError = true;
            return;
          }

          this.questions[this.currIdx].voteStatus = 'VOTING';
          this.liveData = this.liveDataSvc
                              .connect()
                              .subscribe(data => {
                                console.log('LIVE DATA:', data);

                                if (!data)
                                  return;

                                data.type === 'DESCRIPTIVE'
                                  ? this.updateWordCloudChartData(data)
                                  : data.type === 'AMOUNT' 
                                    ? this.updateAmountChartData(data)
                                    : this.updateBarChartData(data);
                              });
        });
  }

  endPoll() {
    this.questionsSvc
        .endPollForQuestion(this.questions[this.currIdx].id)
        .subscribe(success => {
          if (!success) {
            this.showError = true;
            return;
          }

          this.questions[this.currIdx].voteStatus = 'DONE';

          if (!this.questions[this.currIdx].isDelayed) {
            this.liveDataSvc.disconnect();
            this.liveData.unsubscribe();
            this.liveData = undefined;
          } else
            this.updatePoll(this.currIdx);
        });
  }

  processQuestionText() {
    return this.questions[this.currIdx]
               .question
               .replace('\r\n', '\n<br>')
               .replace('\r', '\n<br>')
               .replace('\n', '\n<br>')
               .replace(/\*\*(.+)\*\*/g, '<span class="highlight">$1</span>');
  }

  ///

  private getPolls() {
    this.loading = true;
    this.pollsSvc
        .getPolls()
        .subscribe(polls => {
          this.polls = polls;

          if (this.stateSvc.hasSession() && this.stateSvc.hasPoll()) {
            this.sessionStarted = true;
            this.getQuestions(this.stateSvc.poll);
          }

          this.loading = false;
        });
  }

  private getQuestions(pollId: string){
    this.questionsSvc
        .getQuestions(pollId)
        .subscribe(questions => {
          this.currPoll = this.polls.find(p => p.id === pollId);
          this.questions = questions;
          this.loading = false;

          this.questions.forEach((q, idx) => {
            if (this.stateSvc.hasQuestion(q.id)) {
              q.voteStatus = this.stateSvc.getQuestionStatus(q.id);
              if (q.voteStatus === 'DONE' &&
                   (idx === 0 || (idx > 0 && idx < this.questions.length - 1 && this.questions[idx - 1].voteStatus === 'DONE')))
                ++this.currIdx;
              else if (q.voteStatus === 'VOTING' && !q.isDelayed) {
                this.liveData = this.liveDataSvc
                                    .connect()
                                    .subscribe(data => {
                                      console.log('LIVE DATA:', data);

                                      if (!data)
                                        return;

                                      data.type === 'DESCRIPTIVE'
                                        ? this.updateWordCloudChartData(data)
                                        : data.type === 'AMOUNT' 
                                          ? this.updateAmountChartData(data)
                                          : this.updateBarChartData(data);
                                    });
              }
            } else
              this.stateSvc.setQuestionStatus(q.id, 'NONE');
          });

          this.updatePoll(this.currIdx);

          setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
        });
  }

  private setBarChartOptions(data: Question) {
    this.barChartOptions = {
      chart: {
        type: 'bar',
        spacingRight: 0,
        marginRight: 0,
        height: this.barChartHeight
      },
      title: {
        text: data.question.replace('\n', '\n<br>'),
        useHTML: true,
        style: {
          color: '#333333',
          fontSize: '24px',
          fontWeight: 'bold',
          textAlign: 'center'
        }
      },
      subtitle: {
        text: `Total 0 Votes`
      },
      legend: {
        enabled: false
      },
      tooltip: {
        //valueSuffix: '%',
        followPointer: true,
        followTouchMove: true,
        pointFormat: 'Votes: <b>{point.votes}</b>',
        enabled: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y}%'
          }
        }
      },
      xAxis: {
        categories: data.options
                        .sort((a, b) => a.option.localeCompare(b.option))
                        .map(opt => `Text ${opt.option} - ${opt.description}`),
        labels: {
          style: this.barChartStyle
        },
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        labels: {
          overflow: 'justify'
        },
        title: {
          text: null
        }
      },
      series: [{
        name: 'Votes',
        type: 'bar',
        data: data.options.sort((a, b) => a.option.localeCompare(b.option)).map(d => 0)
      }],
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };

    this.updating = false;
  }

  private setWordCloudChartOptions(data: Question) {
    this.wordCloudOptions = {
      title: {
        text: data.question.replace('\n', '\n<br>'),
        useHTML: true,
        style: {
          color: '#333333',
          fontSize: '24px',
          fontWeight: 'bold',
          textAlign: 'center'
        }
      },
      subtitle: {
        text: `Total 0 Votes`
      },
      series: [{
        name: 'Votes',
        type: 'wordcloud',
        data: []
      }],
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };

    this.updating = false;
  }

  private updateBarChartData(data: LivePollData) {
    
    this.updating = true;

    const newOptions = Object.assign({}, this.barChartOptions);
    newOptions.subtitle.text = `Total ${data.votes} Votes`;

    if (this.questions[this.currIdx].voteStatus === 'DONE' ||
        !this.questions[this.currIdx].isDelayed)
      newOptions.series[0] = {
        name: 'Votes',
        type: 'bar',
        data: data.options
                  .sort((a, b) => a.optCode.localeCompare(b.optCode))
                  .map(o => ({ y: o.percentage, votes: o.total }))
      };

    this.barChartOptions = newOptions;
    this.updating = false;
  }

  private updateBarCharHight(idx) {
    let b = this.questions[idx]?.instructions?.split('\n').filter(i => i.length > 70).length;
    if (b > 1) {
      this.barChartHeight = "650px";
      this.barChartStyle = {
        fontSize: '12px',
        fontWeight: 'bold',
        wordBreak: 'break-all',
        textOverflow: 'allow'
      }
      this.setBarChartOptions(this.questions[this.currIdx]);
    }
  }

  private updateWordCloudChartData(data: LivePollData) {
    this.updating = true;

    const newOptions = Object.assign({}, this.wordCloudOptions);
    newOptions.subtitle.text = `Total ${data.votes} Votes`;

    if (this.questions[this.currIdx].voteStatus === 'DONE' ||
        !this.questions[this.currIdx].isDelayed)
      newOptions.series[0] = {
        name: 'Votes',
        type: 'wordcloud',
        data: data.options.map(opt => ({ name: opt.text, weight: opt.total }))
      };

    this.wordCloudOptions = newOptions;
    this.updating = false;
  }

  private updateAmountChartData(data: LivePollData) {
    this.updating = true;
    console.log(data);
    const newOptions = Object.assign({}, this.wordCloudOptions);
    newOptions.subtitle.text = `Total ${data.options.length} Votes`;
    var d = data.options.map(opt => ({ amount: this.extractAmount(opt.text)}))

    var amt = 0;
    d.forEach(d => {
      if (d.amount) {
        amt = amt + d.amount;
      }
    })
    console.log(amt);

    if (this.questions[this.currIdx].voteStatus === 'DONE' ||
        !this.questions[this.currIdx].isDelayed)
      newOptions.series[0] = {
        name: 'Votes',
        type: 'wordcloud',
        data: [{name: amt, weight: 1}]
      };

    this.wordCloudOptions = newOptions;
    this.updating = false;
  }

  private extractAmount(text: any) {
    var amounts = text.split("||");
    var lastAmount = amounts[amounts.length - 1];
    return parseFloat(lastAmount.replaceAll(/[^0-9.]*/g, ''));
  }

}
