<nav class="navbar navbar-light navbar-expand-lg" *ngIf="isLoggedIn | async">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']">
      <img src="assets/img/logo.png" alt="ENTRADA Logo">
      <span class="d-none d-lg-inline-block">{{title}}</span>
    </a>
    <button class="btn btn-danger btn-sm" (click)="logOut()">Log Out</button>
  </div>
</nav>

<section class="container">
  <router-outlet></router-outlet>
</section>
