import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Poll } from '@models/polls';
import { PollHistory, PollSummary } from '@models/sessions';
import { handleError } from '@utils/handleError';
import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class PollsService {

  private POLLS_URL = environment.apiUrl + '/polls';
  private CURRENT_POLL_URL = environment.apiUrl + '/current-polls';
  private SESSION_URL = environment.apiUrl + '/session-';


  constructor(private http: HttpClient, private state: StateService) { }

  getPolls() {
    return this.http
               .get<Poll[]>(this.POLLS_URL)
               .pipe(
                 map(polls => polls.sort((a, b) => {
                                const retVal = a.clientName.localeCompare(b.clientName);
                                return retVal === 0
                                  ? a.name.localeCompare(b.name)
                                  : retVal;
                              })
                 )
               );
  }

  addPoll(data: { name: string, clientName: string }) {
    return this.http
               .post(this.POLLS_URL, data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  updatePoll(id: string, data: { name: string, clientName: string }) {
    return this.http
               .put(this.POLLS_URL + '/' + id, data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  startSession(pollId: string) {
    return this.http
               .post(this.SESSION_URL + 'start', null, { headers: { 'Poll-ID': pollId } })
               .pipe(
                 map((body: string) => {
                   console.log("Started Session with ID", body);
                   this.state.session = body;
                   this.state.poll = pollId;
                   this.state.questions = {};

                   return true;
                 }),
                 catchError(handleError())
               );
  }

  endSession(pollId: string) {
    return this.http
               .post(this.SESSION_URL + 'end', null, { headers: { 'Poll-ID': pollId } })
               .pipe(
                 map(_ => {
                   console.log("Ended Session with ID", this.state.session);
                   this.state.clearSession();

                   return true;
                 }),
                 catchError(handleError())
               );
  }

  getCurrentPollData(pollId: string) {
    return this.http
               .get<PollHistory>(this.CURRENT_POLL_URL, { headers: { 'Poll-ID': pollId } });
  }

  getPollsSummary(pollId: string) {
    return this.http
               .get<PollSummary>(this.POLLS_URL + '-summary', { headers: { 'Poll-ID': pollId } });
  }

  getPollsHistory(pollId: string) {
    return this.http
               .get<PollHistory>(this.POLLS_URL + '-history', { headers: { 'Poll-ID': pollId } });
  }

}
