<div class="row">
  <div class="col-xs-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4 my-auto">
    <form class="form" [formGroup]="form" (ngSubmit)="doLogin()">
      <div class="text-center">
        <img src="assets/img/logo.png"
          width="200" class="my-1" />
        <h5 class="py-1" *ngIf="isAdmin">A D M I N &nbsp; L O G I N</h5>
      </div>
      <hr>
      <div class="form-group">
        <label for="username">User Name</label>
        <input class="form-control" type="text" formControlName="username" id="username" required>
        <div class="text-danger" *ngIf="isFieldInvalid('username')">Please enter a user name</div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input class="form-control" type="password" formControlName="password" id="password" required>
        <div class="text-danger" *ngIf="isFieldInvalid('password')">Please enter a password</div>
      </div>
      <div class="text-danger" *ngIf="showError">
        Invalid user name and/or password entered!
      </div>
      <br>
      <div class="text-center">
        <button type="submit" class="btn btn-primary">Login</button>
      </div>
    </form>
  </div>
</div>
