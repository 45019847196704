<h3 class="d-flex justify-content-between">
  <span>Past Sessions</span>
  <button class="btn btn-sm btn-outline-secondary" (click)="gotoManagePolls()">Back to Manage Polls</button>
</h3>

<div class="my-2 text-muted" *ngIf="loading">
  Loading...
</div>

<div class="my-2 text-muted" *ngIf="!loading && history?.reports.length < 1">
  No past sessions found.
</div>

<div class="form-group my-2 row" *ngIf="history?.reports.length > 0">
  <label for="session" class="col-3 col-lg-2 col-form-label col-form-label-sm">Select a Session:</label>
  <div class="col-4 col-lg-3">
    <select id="session" class="form-control" (change)="updateSession($event.target.value)">
      <option value="">&mdash; Select &mdash;</option>
      <option *ngFor="let session of history.reports; let idx = index" [value]="session.sessionId"
        [selected]="idx === 0">
        {{ formatDate(session.startTime) }}
      </option>
    </select>
  </div>
  <div class="col-5 col-lg-7 text-right">
    <button class="btn btn-sm btn-outline-info" (click)="recordingMode = !recordingMode">
      {{ recordingMode ? 'View Summary' : 'View Session Recording' }}
    </button>
  </div>
</div>

<hr>

<div *ngIf="!!currSummary && !recordingMode">
  <h6>Users who answered all {{currSummary.sessionData.length}} Questions</h6>
  <table class="table table-borderless table-sm">
    <tr>
      <th width="15%">Phone Number</th>
      <th>Responses</th>
    </tr>
    <tr *ngFor="let user of currSummary.answerSummary">
      <td>{{ user.contactNumber }}</td>
      <td>{{ user.count }}</td>
    </tr>
  </table>
  <ng-container *ngFor="let q of currSummary.sessionData; let idx = index">
    <h6>Question {{ idx + 1 }}: {{ q.question }}</h6>
    <table class="table table-borderless table-sm">
      <tr>
        <th width="15%">Phone Number</th>
        <th width="15%">Response Code</th>
        <th>Response Text</th>
        <th>Json</th>
      </tr>
      <tr *ngFor="let r of q.questionReport">
        <td>{{ r.contactNumber }}</td>
        <td>{{ q.questionType === 'DESCRIPTIVE' ? '' : r.textBody }}</td>
        <td>{{ r.text }}</td>
        <td>{{ r | json }}</td>
      </tr>
    </table>
  </ng-container>
</div>

<div *ngIf="!!currSession && recordingMode">

  <highcharts-chart [Highcharts]="Highcharts" [options]="barChartOptions" [(update)]="updating" id="barchart"
    *ngIf="currSession.results[currIdx].type !== 'DESCRIPTIVE'">
  </highcharts-chart>

  <highcharts-chart [Highcharts]="Highcharts" [options]="wordCloudOptions" [(update)]="updating" id="wordcloud"
    *ngIf="currSession.results[currIdx].type === 'DESCRIPTIVE'">
  </highcharts-chart>

  <div class="d-flex">
    <button class="btn btn-secondary flex-shrink-1" (click)="updatePoll(currIdx - 1)" [disabled]="currIdx < 1">
      Previous Question
    </button>
    <span class="p-2 d-inline-block flex-grow-1 text-center">
      {{ 'Question ' + (currIdx + 1) + ' of ' + currSession.results.length }}
    </span>
    <button class="btn btn-secondary flex-shrink-1" (click)="updatePoll(currIdx + 1)"
      [disabled]="currIdx >= currSession.results.length - 1">
      Next Question
    </button>
  </div>

</div>
