import { Injectable } from '@angular/core';
import * as store from 'store';

import { VoteStatus } from '@models/questions';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  private TOKEN_STRING = 'token';
  private SESSION_STRING = 'session';
  private POLL_STRING = 'poll';
  private QUESTIONS_STRING = 'questions';

  constructor() { }


  //#region TOKEN
  get token() {
    return store.get(this.TOKEN_STRING);
  }

  set token(tokenId: string) {
    store.set(this.TOKEN_STRING, tokenId);
  }

  hasToken() {
    return !!this.token;
  }

  clearToken() {
    store.remove(this.TOKEN_STRING);
  }
  //#endregion


  //#region SESSION
  get session() {
    return store.get(this.SESSION_STRING);
  }

  set session(sessionId: string) {
    store.set(this.SESSION_STRING, sessionId);
  }

  hasSession() {
    return !!this.session;
  }

  clearSession() {
    store.remove(this.SESSION_STRING);
  }
  //#endregion


  //#region POLL
  get poll() {
    return store.get(this.POLL_STRING);
  }

  set poll(pollId: string) {
    store.set(this.POLL_STRING, pollId);
  }

  hasPoll() {
    return !!this.poll;
  }

  clearPoll() {
    store.remove(this.POLL_STRING);
  }
  //#endregion


  //#region QUESTIONS
  get questions() {
    return store.get(this.QUESTIONS_STRING);
  }

  set questions(questionsObj: { [questionId: string]: VoteStatus }) {
    store.set(this.QUESTIONS_STRING, questionsObj);
  }

  clearQuestions() {
    store.remove(this.QUESTIONS_STRING);
  }

  hasQuestion(questionId: string) {
    return !!this.questions?.[questionId];
  }

  getQuestionStatus(questionId: string): VoteStatus {
    return this.questions?.[questionId] ?? 'DONE';
  }

  setQuestionStatus(questionId: string, status: VoteStatus) {
    let questions = this.questions;
    questions[questionId] = status;
    this.questions = questions;
  }
  //#endregion


  clearAll() {
    this.clearToken();
    this.clearSession();
    this.clearPoll();
    this.clearQuestions();
  }

}
