<ng-container *ngIf="!currPoll">
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4 my-auto">
      <form class="form" [formGroup]="form" (ngSubmit)="startSession()">
        <div class="text-center my-3">
          <div class="spinner-border text-secondary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="text-muted my-3" *ngIf="!loading && polls.length < 0">
          No polls found. Please ask your administrator to add a poll.
        </div>
        <div class="form-group" *ngIf="!loading && polls.length > 0">
          <label for="poll">Select Poll</label>
          <select class="form-control" formControlName="pollId" id="poll" required>
            <option value="">&mdash; Select &mdash;</option>
            <option *ngFor="let poll of polls" [value]="poll.id">{{ '[' + poll.clientName + '] ' + poll.name }}</option>
          </select>
          <div class="text-danger" *ngIf="isFieldInvalid('pollId')">Please select a poll to continue</div>
        </div>
        <div class="text-center my-2" *ngIf="!loading && polls.length > 0">
          <button type="submit" class="btn btn-primary">Start Session</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!!currPoll">
  <h1 class="d-flex">
    <p class="flex-grow-1 text-center">
      Vote by texting: <span>(908) 356-7277</span>
    </p>
    <p class="flex-shrink-1">
      <button class="btn btn-secondary" (click)="startPoll()" *ngIf="questions[currIdx]?.voteStatus === 'NONE'">
        Start Poll
      </button>
      <button class="btn btn-danger" (click)="endPoll()" *ngIf="questions[currIdx]?.voteStatus === 'VOTING'">
        End Poll
      </button>
    </p>
  </h1>

  <div class="question" *ngIf="questions[currIdx]?.voteStatus === 'NONE'"
    [innerHTML]="processQuestionText()">
  </div>

  <ul class="my-3 text-muted" *ngIf="questions[currIdx]?.voteStatus === 'NONE'">
    <li *ngFor="let row of questions[currIdx]?.instructions?.split('\n')"
    [ngClass]="{'non-bulleted' : row.indexOf('--') !== 0}" innerHTML="{{ 
      row.indexOf('--') !== 0 ? row : row.substring(2)
    }}"></li>
  </ul>

  <highcharts-chart [Highcharts]="Highcharts" [style.height]="barChartHeight" [options]="barChartOptions" [(update)]="updating" id="barchart"
    *ngIf="chartType !== 'AMOUNT' && chartType !== 'DESCRIPTIVE' && questions[currIdx]?.voteStatus !== 'NONE'">
  </highcharts-chart>

  <highcharts-chart [Highcharts]="Highcharts" [options]="wordCloudOptions" [(update)]="updating" id="wordcloud"
    *ngIf="chartType === 'DESCRIPTIVE' && questions[currIdx]?.voteStatus !== 'NONE'">
  </highcharts-chart>

  <highcharts-chart [Highcharts]="Highcharts" [options]="wordCloudOptions" [(update)]="updating" id="wordcloud"
  *ngIf="chartType === 'AMOUNT' && questions[currIdx]?.voteStatus !== 'NONE'">
</highcharts-chart>

  <div class="d-flex">
    <button class="btn btn-secondary flex-shrink-1" (click)="updatePoll(currIdx - 1)"
      [disabled]="currIdx < 1 || questions[currIdx]?.voteStatus === 'VOTING'">
      Previous Question
    </button>
    <span class="p-2 d-inline-block flex-grow-1 text-center">
      {{ 'Question ' + (currIdx + 1) + ' of ' + questions.length }}
    </span>
    <button class="btn btn-secondary flex-shrink-1" (click)="updatePoll(currIdx + 1)"
      [disabled]="currIdx >= questions.length - 1 || questions[currIdx]?.voteStatus === 'VOTING'">
      Next Question
    </button>
  </div>
</ng-container>
