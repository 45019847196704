import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Option, OptionData } from '@models/questions';
import { QuestionsService } from '@api/questions.service';


@Component({
  selector: 'app-option-modal',
  templateUrl: './option-modal.component.html',
  styleUrls: ['./option-modal.component.scss']
})
export class OptionModal implements OnInit {

  questionId: string;
  option: Option;
  form: FormGroup;
  mode: 'Add' | 'Update';

  submitted = false;
  showError = false;

  constructor(private fb: FormBuilder,
              private modalRef: BsModalRef,
              private questions: QuestionsService) { }

  ngOnInit(): void {
    this.mode = !!this.option ? 'Update' : 'Add';
    this.form = this.fb.group({
      option: [this.option?.option ?? '', Validators.required],
      description: [this.option?.description ?? '', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return (control.invalid &&
      (control.touched || (control.untouched && this.submitted))
    );
  }

  doSubmit() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    const postData: OptionData = {
      option: this.form.value.option,
      description: this.form.value.description,
      questionId: this.questionId
    };

    this.mode === 'Add'
      ? this.questions.addOption(postData).subscribe(this.handleResponse.bind(this))
      : this.questions.updateOption(this.option.id, postData).subscribe(this.handleResponse.bind(this));
  }

  handleResponse(success: boolean) {
    success && this.close();
    this.showError = !success;
  }

  close() {
    this.modalRef.hide();
  }

}
