<h3>Manage Polls</h3>
<table class="table">
  <tr>
    <th width="45%">Name</th>
    <th>Client</th>
    <th>Created On</th>
    <th><button class="btn btn-outline-secondary btn-sm" (click)="showAddPoll()">Add Poll</button></th>
  </tr>
  <tr *ngIf="isLoading">
    <td colspan="4" class="text-muted">Loading...</td>
  </tr>
  <tr *ngIf="!isLoading && polls?.length < 1">
    <td colspan="4" class="text-muted">Please add a poll</td>
  </tr>
  <tr *ngFor="let poll of polls">
    <td>{{ poll.name }}</td>
    <td>{{ poll.clientName }}</td>
    <td>{{ poll.createdOn }}</td>
    <td>
      <div class="btn-group btn-group-sm">
        <button class="btn btn-outline-secondary" (click)="showUpdatePoll(poll.id)">Edit Poll</button>
        <button class="btn btn-outline-secondary" (click)="gotoQuestions(poll.id)">Manage Questions</button>
        <button class="btn btn-outline-secondary" (click)="gotoHistory(poll.id)">View History</button>
      </div>
    </td>
  </tr>
</table>

<!--
<div *ngIf="!currQues" id="questions">
  <h5>Questions:</h5>
  <ol *ngIf="questions?.length > 0">
    <li *ngFor="let q of questions">
      <div>
        <b>{{ q.question }}</b>
        &nbsp;
        <div class="btn-group btn-group-sm">
          <button class="btn btn-outline-info" (click)="editQuestion(q.id)">Edit</button>
          <button class="btn btn-outline-info" (click)="editQuestion(q.id)">Add Option</button>
          <button class="btn btn-outline-danger" (click)="editQuestion(q.id)" *ngIf="!q.isArchived">Disable</button>
          <button class="btn btn-outline-success" (click)="editQuestion(q.id)" *ngIf="q.isArchived">Enable</button>
        </div>
      </div>
      <! --
      <ol *ngIf="q.options.length > 0">
        <li *ngFor="let opt of q.options">Text <b>{{ opt.option }}</b>: {{ opt.description }}</li>
      </ol>
      - ->
      <div class="row col-6" *ngIf="q.options.length > 0">
        <div class="col-3 text-right"><b>SMS Code</b></div>
        <div class="col-8"><b>Option Text</b></div>
        <div class="col-1"><b>Action</b></div>
      </div>
      <div class="row col-6" *ngFor="let opt of q.options">
        <div class="col-3 text-right">{{ opt.option }}</div>
        <div class="col-8">{{ opt.description }}</div>
        <div class="col-1">
          <div class="btn-group btn-group-sm">
            <button class="btn btn-outline-info" (click)="editQuestion(q.id)">Edit</button>
          </div>
        </div>
      </div>
    </li>
  </ol>
  <div *ngIf="questions?.length > 0">
    <div *ngFor="let q of questions">
      <div class="row">
        <div class="col-10">
          <b>{{ q.question }}</b>
        </div>
        <div class="col-2 text-right">
          <div class="btn-group btn-group-sm">
            <button class="btn btn-outline-info" (click)="editQuestion(q.id)">Edit</button>
            <button class="btn btn-outline-info" (click)="editQuestion(q.id)">Add Option</button>
            <button class="btn btn-outline-danger" (click)="editQuestion(q.id)" *ngIf="!q.isArchived">Disable</button>
            <button class="btn btn-outline-success" (click)="editQuestion(q.id)" *ngIf="q.isArchived">Enable</button>
          </div>
        </div>
      </div>
      <div class="row small" *ngIf="q.options.length > 0">
        <div class="col-2 text-right"><b>SMS Code</b></div>
        <div class="col-9"><b>Option Text</b></div>
        <div class="col-1"><b>Action</b></div>
      </div>
      <div class="row small" *ngFor="let opt of q.options">
        <div class="col-2 text-right">{{ opt.option }}</div>
        <div class="col-9">{{ opt.description }}</div>
        <div class="col-1">
          <div class="btn-group btn-group-sm">
            <button class="btn btn-outline-info" (click)="editQuestion(q.id)">Edit</button>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
  <br>
  <div class="my-2">
    <button class="btn btn-secondary" (click)="addQuestion()">Add Question</button>
  </div>
</div>
<form class="form" (ngSubmit)="doSubmit()" [formGroup]="form" *ngIf="currQues">
  <div class="form-group">
    <label for="question">Question</label>
    <input type="text" class="form-control" formControlName="question" id="question" required>
    <div class="text-danger" *ngIf="isFieldInvalid('question')">Please enter question text</div>
  </div>
  <div class="my-2">
    <button class="btn btn-secondary" type="submit">Update</button>
  </div>
</form>
-->
