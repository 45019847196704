import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@api/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginPage implements OnInit {

  private submitted = false;

  form: FormGroup;

  showError = false;
  isAdmin = false;


  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.isAdmin = this.router.url.indexOf('admin') >= 0;
    this.form = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return (
      (!control.valid && control.touched) ||
      (control.untouched && this.submitted)
    );
  }

  doLogin() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    this.auth
      .login(this.form.value.username, this.form.value.password)
      .subscribe(success => {
        success && this.router.navigate([this.isAdmin ? '/admin/manage-polls' : '/session']);
        this.showError = !success;
      });
  }

}
