import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@api/auth.service';


@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayout {

  title = 'Live Feedback';

  isLoggedIn: Observable<boolean>;

  constructor(private auth: AuthService, private router: Router) {
    this.isLoggedIn = auth.isLoggedIn;
  }

  logOut() {
    this.auth.logout().subscribe(async success => {
      success && await this.router.navigate(['/login']);
    });
  }

}
