import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AnswerData, PollActive } from '@models/answers';
import { handleError } from '@utils/handleError';
import { Question } from '@models/questions';


@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(private http: HttpClient) { }

  isPollActive() {
    return this.http
               .get<PollActive>(environment.apiUrl + '/question/any-active');
  }

  getCurrentQuestion() {
    return this.http
               .get<Question>(environment.apiUrl + '/question/get-active');
  }

  sendAnswer(data: AnswerData) {
    if (!data?.From?.length || !data?.Body?.length)
      return;

    const params = new HttpParams({ fromObject: { From: data.From, Body: data.Body } });

    return this.http
               .post(
                 environment.apiUrl + '/answer-poll',
                 params.toString(),
                 { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
               )
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

}
