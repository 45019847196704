import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Poll } from '@models/polls';
import { PollsService } from '@api/polls.service';



@Component({
  selector: 'app-poll-modal',
  templateUrl: './poll-modal.component.html',
  styleUrls: ['./poll-modal.component.scss']
})
export class PollModal implements OnInit {

  poll: Poll;
  form: FormGroup;
  mode: 'Add' | 'Update';

  submitted = false;
  showError = false;


  constructor(private fb: FormBuilder,
              private modalRef: BsModalRef,
              private polls: PollsService) { }

  ngOnInit() {
    this.mode = !!this.poll ? 'Update' : 'Add';
    this.form = this.fb.group({
      name: [this.poll?.name || '', Validators.required],
      clientName: [this.poll?.clientName || '', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return (
      (control.invalid && control.touched) ||
      (control.invalid && control.untouched && this.submitted)
    );
  }

  doSubmit() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    this.mode === 'Add'
      ? this.polls.addPoll(this.form.value).subscribe(this.handleResponse.bind(this))
      : this.polls.updatePoll(this.poll.id, this.form.value).subscribe(this.handleResponse.bind(this));
  }

  handleResponse(success: boolean) {
    success && this.close();
    this.showError = !success;
  }

  close() {
    this.modalRef.hide();
  }

}
