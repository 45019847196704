<h4>Please enter your response below</h4>

<br>

<div class="question" [innerHTML]="processQuestionText()"></div>

<ul class="my-3 text-muted">
  <li *ngFor="let row of question?.instructions?.split('\n')">{{ row }}</li>
</ul>

<div class="row">
  <div class="col-xs-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
    <form [formGroup]="form" (ngSubmit)="doSubmit()">
      <div class="form-group">
        <label for="phone">Your Phone</label>
        <input class="form-control" type="tel" formControlName="phone" id="phone" required>
        <div class="text-danger" *ngIf="isFieldInvalid('phone')">Please enter a valid phone number</div>
      </div>
      <div class="form-group">
        <label for="answer">Your Answer</label>
        <input class="form-control" type="text" formControlName="answer" id="answer" required>
        <div class="text-danger" *ngIf="isFieldInvalid('answer')">Please enter an answer</div>
      </div>
      <div class="text-success" *ngIf="showSuccess">
        Your answer was submitted successfully!
      </div>
      <div class="text-danger" *ngIf="showError">
        Your answer could not be submitted!
      </div>
      <br>
      <div class="text-center">
        <button type="submit" class="btn btn-primary">Submit Answer</button>
      </div>
    </form>
  </div>
</div>
