<div class="modal-header">
  <h4 class="modal-title pull-left">{{ mode }} Question</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form" (ngSubmit)="doSubmit()" [formGroup]="form">
    <div class="form-group">
      <label for="name">Question Text</label>
      <textarea formControlName="question" id="question" class="form-control" cols="50" rows="3" required></textarea>
      <div class="text-danger" *ngIf="isFieldInvalid('question')">Please enter question text</div>
    </div>
    <div class="form-group">
      <label for="instructions">Instructions Text</label>
      <textarea formControlName="instructions" id="instructions" class="form-control" cols="50" rows="3"></textarea>
    </div>
    <div class="form-group">
      <label for="type">Question Type</label>
      <select class="form-control" formControlName="type" id="type" required>
        <option value="DESCRIPTIVE">Open Text</option>
        <option value="OPTIONAL">Single Choice</option>
        <option value="MULTIPLE">Multi Choice</option>
        <option value="AMOUNT">Amount</option>
      </select>
      <div class="text-danger" *ngIf="isFieldInvalid('type')">Please select a question type</div>
    </div>
    <div class="form-group" *ngIf="form.controls['type'].value === 'MULTIPLE'">
      <label for="maxResponses">Max Responses</label>
      <input type="number" min="2" step="1" class="form-control" formControlName="maxResponses"
        id="maxResponses" required>
      <div class="text-danger" *ngIf="isFieldInvalid('maxResponses')">Please enter max responses a question can have</div>
    </div>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" formControlName="isDelayed" id="isDelayed">
      <label class="form-check-label" for="isDelayed">Hide Live Results</label>
    </div>
    <div class="text-danger" *ngIf="showError">
      An error occurred while saving the option. Please try again after some time.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="close()">Cancel</button>
  <button type="button" class="btn btn-outline-info" (click)="doSubmit()">{{ mode }} Question</button>
</div>
