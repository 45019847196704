import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DefaultLayout } from './layouts/default-layout/default-layout.component';
import { AdminLayout } from './layouts/admin-layout/admin-layout.component';

import { LoginPage } from './pages/login/login.component';
import { CurrentSessionPage } from './pages/current-session/current-session.component';
import { ManagePollsPage } from './pages/manage-polls/manage-polls.component';
import { PastSessionsPage } from './pages/past-sessions/past-sessions.component';
import { ManageQuestionsPage } from '@pages/manage-questions/manage-questions.component';
import { PublicLayout } from './layouts/public-layout/public-layout.component';
import { AnswerPollPage } from '@pages/answer-poll/answer-poll.component';


const routes: Routes = [{
  path: '',
  component: DefaultLayout,
  children: [{
    path: 'session',
    component: CurrentSessionPage
  }, {
    path: 'login',
    component: LoginPage
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: '/session'
  }]
}, {
  path: 'admin',
  component: AdminLayout,
  children: [{
    path: 'manage-polls',
    component: ManagePollsPage
  }, {
    path: 'manage-questions/:pollId',
    component: ManageQuestionsPage
  }, {
    path: 'past-sessions/:pollId',
    component: PastSessionsPage
  }, {
    path: 'login',
    component: LoginPage
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: '/admin/manage-polls'
  }]
}, {
  path: 'public',
  component: PublicLayout,
  children: [{
    path: 'answer-poll',
    component: AnswerPollPage
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: '/public/answer-poll'
  }]
}, {
  path: '**',
  redirectTo: ''
}];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
