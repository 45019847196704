<nav class="navbar navbar-light" *ngIf="isLoggedIn | async">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/admin']">
      <img src="assets/img/logo.png" alt="ENTRADA Logo">
      <span class="d-none d-lg-inline-block">{{title}}</span>
    </a>

    <div class="flex-grow-1">&nbsp;</div>

    <!--
    <div class="btn-group" dropdown>
      <button id="menu-btn" dropdownToggle type="button" class="btn"
        aria-controls="navbarLinks" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul id="navbarLinks" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="menu-btn">
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/admin/manage-polls']" routerLinkActive="active">Manage Polls</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/admin/past-sessions']" routerLinkActive="active">Past Sessions</a>
        </li>
      </ul>
    </div>
    -->
    <button class="btn btn-danger btn-sm" (click)="logOut()">Log Out</button>
  </div>
</nav>

<section class="container">
  <router-outlet></router-outlet>
</section>
