import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment } from 'src/environments/environment';
import { LivePollData } from '@models/polls';


@Injectable({
  providedIn: 'root'
})
export class LiveDataService {

  private DATA_URL = environment.apiUrl.replace(/^http/i, 'ws') + '/ws-poll';

  private subject: WebSocketSubject<LivePollData>;

  connect() {
    this.subject = webSocket(this.DATA_URL);
    return this.subject.asObservable();
  }

  disconnect() {
    this.subject.unsubscribe();
    this.subject = undefined;
  }

}
