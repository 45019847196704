import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhoneValidators } from 'ngx-phone-validators';

import { AnswerService } from '@api/answer.service';
import { Question } from '@models/questions';


@Component({
  selector: 'app-answer-poll',
  templateUrl: './answer-poll.component.html',
  styleUrls: ['./answer-poll.component.scss']
})
export class AnswerPollPage implements OnInit {

  private submitted = false;

  question: Question;
  form: FormGroup;
  showError = false;
  showSuccess = false;

  constructor(private fb: FormBuilder, private answer: AnswerService) { }

  ngOnInit() {
    this.form = this.fb.group({
      phone: [{ value: '', disabled: true }, Validators.compose([Validators.required, PhoneValidators.isPossibleNumberWithReason('US')])],
      answer: [{ value: '', disabled: true }, Validators.required]
    });

    setInterval(this.doPollCheck.bind(this), 2500);
  }

  processQuestionText() {
    return this.question
               .question
               .replace('\r\n', '\n<br>')
               .replace('\r', '\n<br>')
               .replace('\n', '\n<br>')
               .replace(/\*\*(.+)\*\*/g, '<span class="highlight">$1</span>');
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return (
      (!control.valid && control.touched) ||
      (control.untouched && this.submitted && this.form.enabled)
    );
  }

  doSubmit() {
    if (this.form.disabled)
      return;

    this.submitted = true;

    if (this.form.invalid)
      return;

    this.answer
        .sendAnswer({ From: this.form.value.phone, Body: this.form.value.answer })
        .subscribe(success => {
          if (success) {
            this.showSuccess = true;
            this.setFormState(false);
            return;
          }

          this.showError = true;
        });
  }

  ///

  private doPollCheck() {
    this.answer
        .isPollActive()
        .subscribe(pollState => {

          if (this.submitted && pollState.isAnyActivePoll)
            return;
          else if (this.submitted && !pollState.isAnyActivePoll)
            this.submitted = false;

          this.setFormState(pollState.isAnyActivePoll);
          pollState.isAnyActivePoll && this.getCurrentQuestion();
        });
  }

  private getCurrentQuestion() {
    this.answer
        .getCurrentQuestion()
        .subscribe(question => (this.question = question));
  }

  private setFormState(state: boolean) {
    if ((state && this.form.enabled) || (!state && this.form.disabled))
      return;

    if (state) {
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.enable();
      this.showSuccess = false;
    }
    else {
      this.showError = false;
      this.form.controls['answer'].setValue('');
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.disable();
    }
  }

}
