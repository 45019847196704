import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';

import { AppComponent } from './app.component';
import { DefaultLayout } from './layouts/default-layout/default-layout.component';
import { AdminLayout } from './layouts/admin-layout/admin-layout.component';
import { PublicLayout } from './layouts/public-layout/public-layout.component';

import { LoginPage } from './pages/login/login.component';
import { CurrentSessionPage } from './pages/current-session/current-session.component';
import { ManagePollsPage } from './pages/manage-polls/manage-polls.component';
import { ManageQuestionsPage } from './pages/manage-questions/manage-questions.component';
import { PastSessionsPage } from './pages/past-sessions/past-sessions.component';
import { AnswerPollPage } from './pages/answer-poll/answer-poll.component';

import { PollModal } from './modals/poll-modal/poll-modal.component';
import { QuestionModal } from './modals/question-modal/question-modal.component';
import { OptionModal } from './modals/option-modal/option-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    DefaultLayout,
    AdminLayout,
    PublicLayout,
    LoginPage,
    CurrentSessionPage,
    ManagePollsPage,
    ManageQuestionsPage,
    PastSessionsPage,
    AnswerPollPage,
    PollModal,
    QuestionModal,
    OptionModal
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HighchartsChartModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
