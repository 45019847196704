<div class="modal-header">
  <h4 class="modal-title pull-left">{{ mode }} Poll</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form" (ngSubmit)="doSubmit()" [formGroup]="form">
    <div class="form-group">
      <label for="name">Poll Name</label>
      <input type="text" class="form-control" formControlName="name" id="name" required>
      <div class="text-danger" *ngIf="isFieldInvalid('name')">Please enter a poll name</div>
    </div>
    <div class="form-group">
      <label for="clientName">Client Name</label>
      <input type="text" class="form-control" formControlName="clientName" id="clientName" required>
      <div class="text-danger" *ngIf="isFieldInvalid('clientName')">Please enter a client name</div>
    </div>
    <div class="text-danger" *ngIf="showError">
      An error occurred while saving the option. Please try again after some time.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="close()">Cancel</button>
  <button type="button" class="btn btn-outline-info" (click)="doSubmit()">{{ mode }} Poll</button>
</div>
