<div class="modal-header">
  <h4 class="modal-title pull-left">{{ mode }} Option</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form" (ngSubmit)="doSubmit()" [formGroup]="form">
    <div class="form-group">
      <label for="option">SMS Code</label>
      <input type="text" class="form-control" formControlName="option" id="option" required>
      <div class="text-danger" *ngIf="isFieldInvalid('option')">Please enter an sms code</div>
    </div>
    <div class="form-group">
      <label for="description">Option Text</label>
      <input type="text" class="form-control" formControlName="description" id="description" required>
      <div class="text-danger" *ngIf="isFieldInvalid('description')">Please enter option text</div>
    </div>
    <div class="text-danger" *ngIf="showError">
      An error occurred while saving the option. Please try again after some time.<br />
      Remember to use unique SMS Code for each option.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="close()">Cancel</button>
  <button type="button" class="btn btn-outline-info" (click)="doSubmit()">{{ mode }} Option</button>
</div>
